<template>
  <!--
    The view for Inbox-component.
    Used to display Inbox.
  -->
  <Portlet
    title="Inbox"
    icon="envelope-open"
  >
    <Inbox :note-id="noteId" />
  </Portlet>
</template>

<script>
export default {
  name: "InboxView",
  components: {
    Inbox: () => import('@/components/History/Inbox.vue')
  },
  props: {
    noteId: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  metaInfo () {
    return {
      title: 'Inbox'
    }
  }
}
</script>